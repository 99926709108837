<template>
<div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>

    <div class="row justify-content-md-center">
      <div class="col-12">
        <filter-side-bar>
          <template slot="ContentSideBar">
            <form id="processes-list-filter" ref='processesListFilter' v-on:submit.prevent="applyFilter()">
              <h5 class="mb-0">Filtrar por:</h5>
              <div>
                <div>
                  <multiple-select :options="personList"  labelField="nome" :id-field="'id'" :placeholder="this.$i18n.t('form.customer.label')" :fetchOptions="personFetchOptions" :value="filters.filCliente" />
                </div>
              </div>
              <div class="py-1 text-center">
                <div class="md-form mb-0">
                  <div class="row">
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary btn-fill btn-block mr-2 ">{{ $t('filter.apply') }}
                      </button>
                    </div>
                    <div class="col-6">
                      <button type="button" v-on:click="resetFilter" class="btn btn-cancel btn-fill btn-block mr-2 ">
                        {{ $t('filter.reset') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </template>
        </filter-side-bar>
      </div>
      <!-- Bloco com os Filtros -->
      <div class="col-12 mb-3">
        <filter-container :activeFilterList="activeFilterList" />
      </div>

      <div class="col-12 col-sm-12">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <!-- Table -->
                <custom-vue-table
                  ref="vuetable"
                  http-method="get"
                  :api-url="loadEndpoint"
                  :fields="fieldsTable"
                  :sort-order="sortOrder"
                  :append-params="activeFilters"
                  track-by="id"
                >
                  <template slot="actions" slot-scope="props">
                    <div class="custom-actions">
                      <a class="btn btn-link btn-padding" :title="$t('actions.view')"
                         v-bind:href="$util.mountLink('TierSettingsEdit', props.rowData)">
                        <i class="text-primary rc-Ativo-210 font-20"></i>
                      </a>
                    </div>
                  </template>
                </custom-vue-table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
</div>
</template>

<script>
import CustomVueTable from '@/components/Table/CustomVueTable'

import FilterSideBar from '@/components/FilterSideBar.vue'
import FilterContainer from '@/components/FilterContainer'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components

// MultipleSelect
import MultipleSelect from '@/components/Forms/MultipleSelect.vue'

// Get services
import PersonService from '@/services/PersonService'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

export default {
  name: 'TierSettings',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('sidebar.tier_settings') + ' - %s'
    }
  },
  data () {
    return {
      tierLocalList: [],
      tierFreteList: [],
      personTypeList: [],
      personList: [],
      isLoading: false,
      fullPage: true,
      selectedProcess: null,
      processDetail: false,
      loadEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/pessoas/tier/settings',
      multiSort: true,
      activeFilterList: {
        customer: null,
        id_status: null
      },
      filter: {
        customer: [],
        id_status: null
      },
      sortOrder: [
        { field: 'u.ativo', direction: 'asc' },
        { field: 'u.nome', direction: 'asc' }
      ]
    }
  },
  components: {
    CustomVueTable,
    FilterSideBar,
    Loading,
    FilterContainer,
    MultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {
    this.buildFilters()
  },
  mounted () {
    this.$store.dispatch('SetShowFilter', true)
    this.$store.dispatch('setShowSearch', false)

    this.$jQuery('#arrival_begin_date, #arrival_end_date, #departure_begin_date, #departure_end_date').datepicker({
      language: 'pt-BR',
      calendarWeeks: true,
      autoclose: true,
      templates: {
        leftArrow: '<i class="ti-angle-left"></i>',
        rightArrow: '<i class="ti-angle-right"></i>'
      }
    })
  },
  methods: {
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    personFetchOptions (search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let filters = {
          'searchTerm': search
        }

        PersonService.getPersons(filters).then(res => {
          _this.personList = res.data.data

          resolve(_this.personList)
        }).catch(err => {
          reject(err)
        })
      })
    },
    buildFilters () {
      // teste
      this.filter.customer = (this.filters.filCliente) ? this.filters.filCliente.map(x => (x.id)) : []

      this.filter.id_status = null
    },
    // Loader
    applyFilter () {
      this.personList = []

      this.buildFilters()

      this.$store.dispatch('setAppliedFilter', this.filters)

      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }

      this.hideQuichSidebar()
    },
    resetFilter (event) {
      event.preventDefault()
      this.hideQuichSidebar()

      this.filters.filCliente = []
      this.$store.dispatch('setAppliedFilter', this.filters)

      this.buildFilters()

      if (this.$refs.vuetable !== undefined) {
        this.$refs.vuetable.onFilter()
      }
    },
    hideQuichSidebar () {
      const sideBar = document.querySelector('#quick-sidebar')
      document.body.classList.remove('has-backdrop')
      sideBar.classList.remove('shined')
    }
  },
  computed: {
    fieldsTable () {
      return [
        {
          name: 'cpf_cnpj',
          title: this.$i18n.t('form.customer.cpfCnpj'),
          sortField: 'u.cpf_cnpj',
          width: '20%',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('form.billingPrefenrece.nome'),
          sortField: 'u.nome',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tier_frete_expo_code',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[3]') + ' ' + this.$i18n.t('Frete Expo'),
          sortField: 'tier_frete_expo_code',
          width: '60px',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tier_local_expo_code',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[3]') + ' ' + this.$i18n.t('Local Expo'),
          sortField: 'tier_local_expo_code',
          width: '60px',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tier_frete_impo_code',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[3]') + ' ' + this.$i18n.t('Frete Impo'),
          sortField: 'tier_frete_impo_code',
          width: '60px',
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'tier_local_impo_code',
          width: '60px',
          sortField: 'tier_local_impo_code',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[3]') + ' ' + this.$i18n.t('Local Impo'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'custom_frete_lcl_impo',
          width: '60px',
          sortField: 'custom_frete_lcl_impo',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[4]') + ' ' + this.$i18n.t('Frete Impo'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatBoolean(value)
        },
        {
          name: 'tier_local_lcl_expo_code',
          sortField: 'tier_local_lcl_expo_code',
          width: '60px',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[4]') + ' ' + this.$i18n.t('Local Expo'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'custom_frete_lcl_expo',
          width: '60px',
          sortField: 'custom_frete_lcl_expo',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[4]') + ' ' + this.$i18n.t('Frete Expo'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatBoolean(value)
        },
        {
          name: 'tier_local_lcl_impo_code',
          width: '60px',
          sortField: 'tier_local_lcl_impo_code',
          title: this.$i18n.t('form.tierLocal.tipoCargaList[4]') + ' ' + this.$i18n.t('Local Impo'),
          dataClass: 'text-left',
          titleClass: 'text-left',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          width: '10%',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    activeFilters () {
      return this.filter
    },
    user () {
      return this.$store.getters.userInfo || null
    },
    filters () {
      if (this.$Cookie.get('_userStoredFilters_')) {
        return JSON.parse(this.$Cookie.get('_userStoredFilters_'))
      } else {
        return null
      }
    },
    refreshFilter () {
      return this.$store.getters.getRefreshFilter || null
    },
    getTiersLocaisPorOperacaoExpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(1, 3)
    },
    getTiersLocaisPorOperacaoImpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(2, 3)
    },
    getTiersLocaisLclPorOperacaoExpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(1, 4)
    },
    getTiersLocaisLclPorOperacaoImpo () {
      return this.getTiersLocaisPorOperacaoTipoCarga(2, 4)
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    refreshFilter: function () {
      if (this.refreshFilter) {
        this.applyFilter()
        this.$store.dispatch('setRefreshFilter', false)
      }
    }
  }
}
</script>

<style scoped>
  table th {
    padding: 0;
  }

  table tr:last-child th {
    padding-bottom: 5px;
  }

  .no-border {
    border: 0;
  }
</style>
